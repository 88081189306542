var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.editItem != null)?_c('b-modal',{attrs:{"centered":"","scrollable":"","size":"lg","title":"知识点编辑","ok-title":"保存","cancel-title":"取消","visible":_vm.visible},on:{"hidden":_vm.onHidden,"ok":_vm.saveChanges}},[_c('validation-observer',{ref:"formRules"},[_c('app-collapse',[_c('app-collapse-item',{ref:"baseInfo",attrs:{"title":"基本信息","isVisible":true}},[_c('validation-provider',{attrs:{"name":"*标题*","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"标题","label-for":"input-course-name","label-cols-md":"3"}},[_c('b-form-input',{attrs:{"id":"input-course-name","placeholder":"标题"},model:{value:(_vm.editItem.title),callback:function ($$v) {_vm.$set(_vm.editItem, "title", $$v)},expression:"editItem.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1341144430)}),_c('validation-provider',{attrs:{"name":"*适用法律*","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"适用法律","label-for":"input-course-apply","label-cols-md":"3"}},[_c('b-form-select',{attrs:{"id":"input-course-apply","placeholder":"适用法律","options":_vm.complianceOptionsClean},model:{value:(_vm.editItem.apply),callback:function ($$v) {_vm.$set(_vm.editItem, "apply", $$v)},expression:"editItem.apply"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4209251370)}),_c('validation-provider',{attrs:{"name":"*文档类型*","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"文档类型","label-for":"input-course-type","label-cols-md":"3"}},[_c('b-form-select',{attrs:{"id":"input-course-type","placeholder":"文档类型","options":_vm.typeOptions},model:{value:(_vm.editItem.type),callback:function ($$v) {_vm.$set(_vm.editItem, "type", $$v)},expression:"editItem.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1796668326)})],1),_c('app-collapse-item',{ref:"baseInfo",attrs:{"title":"内容"}},[_c('validation-provider',{attrs:{"name":"*内容*","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"message-editor"},[_c('quill-editor',{attrs:{"id":"quil-content","options":_vm.editorOption},model:{value:(_vm.editItem.content),callback:function ($$v) {_vm.$set(_vm.editItem, "content", $$v)},expression:"editItem.content"}}),_c('div',{staticClass:"d-flex border-0",attrs:{"id":"quill-toolbar"}},[_c('button',{staticClass:"ql-bold"}),_c('button',{staticClass:"ql-italic"}),_c('button',{staticClass:"ql-underline"}),_c('button',{staticClass:"ql-align"}),_c('button',{staticClass:"ql-link"})])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2846955706)})],1),_c('app-collapse-item',{attrs:{"title":"附件资料"}},[_c('b-form-group',{attrs:{"label":"附件资料","label-for":"input-course-attachment","label-cols-md":"3"}},[_c('b-form-input',{attrs:{"id":"input-course-attachment","placeholder":"附件资料"}})],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }