<template>
  <b-card>
    <!-- search & new -->
    <b-row>
      <b-col cols="3">
        <b-form-select
          placeholder="合规要求"
          v-model="query_compliance"
          :options="complianceOptions"
        />
      </b-col>
      <b-col cols="3">
        <b-form-select
          placeholder="知识类型"
          v-model="query_type"
          :options="typeOptions"
        />
      </b-col>
      <b-col cols="4">
        <b-input-group>
          <b-form-input
            type="text"
            v-model="query_keyword"
            placeholder="请输入搜索内容"
            @keypress.enter="doSearch"
          />
          <b-input-group-append is-text>
            <feather-icon
              icon="SearchIcon"
              class="cursor-pointer"
              @click="doSearch"
            />
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="2" class="text-right">
        <b-button variant="primary" @click="newItem"
          ><feather-icon icon="PlusIcon" class="mr-50" /><span
            class="align-middle"
            >添加知识点</span
          ></b-button
        >
      </b-col>
    </b-row>

    <!-- list -->
    <b-row>
      <b-col cols="12">
        <b-table
          ref="listTable"
          selectable
          select-mode="multi"
          :items="listTableItems"
          :fields="listTableFields"
          striped
          hover
          responsive
          :per-page="listTablePerPage"
          :current-page="listTableCurrentPage"
          class="mt-1"
        >
          <template #cell(compliances)="data">
            <b-badge
              v-for="(item, idx) in data.value"
              :key="idx"
              class="mr-1"
              variant="primary"
              >{{ item }}</b-badge
            >
          </template>

          <template #cell(attachments)="data">
            <b-card-text>{{ data & data.length ? "has" : "no" }}</b-card-text>
          </template>

          <template #cell(actions)="data">
            <b-button
              variant="flat-success"
              class="btn-icon"
              @click="editItem(data)"
              ><feather-icon icon="EditIcon"
            /></b-button>
            <b-button
              variant="flat-danger"
              class="btn-icon"
              @click="deleteItem(data)"
              ><feather-icon icon="DeleteIcon"
            /></b-button>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <b-pagination
          v-model="listTableCurrentPage"
          :total-rows="listTableTotalRows"
          :per-page="listTablePerPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>

    <!-- edit -->
    <CourseEdit
      :item="currentItem"
      :visible="showEdit"
      @hidden="onEditHidden"
      @changed="doSearch"
    />
  </b-card>
</template>


<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormSelect,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  BBadge,
  BButton,
  BPagination,
  BFormGroup,
  BFormInvalidFeedback,
  BAvatar,
  BFormCheckbox,
} from "bootstrap-vue";
import CourseEdit from "./CourseEdit.vue";

export default {
  name: "EnterpriseList",

  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BAvatar,
    BFormCheckbox,
    CourseEdit,
  },

  data() {
    return {
      queryData: null,

      // select options
      typeOptions: [
        { value: "", text: "知识类型" },
        { value: "law", text: "知识类型" },
        { value: "case", text: "经典案例" },
        { value: "explain", text: "条款解读" },
      ],
      complianceOptions: [
        { value: "", text: "合规要求" },
        "GDPR",
        "CCPA",
        "PIPL",
      ],

      // query
      query_type: "",
      query_compliance: "",
      query_keyword: "",

      // list table
      listTableFields: [
        { key: "code", label: "编号" },
        { key: "compliances", label: "合规要求" },
        { key: "title", label: "标题" },
        { key: "type", label: "类型" },
        { key: "actions", label: "操作" },
      ],
      listTablePerPage: 5,
      listTablePageOptions: [5, 15, 30],
      listTableTotalRows: 1,
      listTableCurrentPage: 1,

      // edit
      currentItem: null,
      baseInfoVisible: true,
      adminUser: null,
      showEdit: false,
    };
  },

  computed: {
    listTableItems() {
      return this.queryData ? this.queryData.courses : [];
    },
  },

  methods: {
    doSearch() {
      this.$http
        .get("/api/course", {
          params: {
            q: this.query_keyword,
            industry: this.query_industry,
            compliance: this.query_compliance,
          },
        })
        .then((res) => {
          this.queryData = res.data;
        });
    },

    //
    editItem(data) {
      this.currentItem = JSON.parse(JSON.stringify(data.item));
      this.showEdit = true;
    },

    //
    newItem() {
      const item = {
        id: 0,
        code: null,
        title: null,
        content: null,
        apply: null,
        attachments: [],
        status: "active",
      };
      this.currentItem = item;
      this.adminUser = null;
      this.showEdit = true;
    },

    //
    deleteItem(data) {
      let name = data.item.name;
      this.$bvModal
        .msgBoxConfirm(`是否确定删除知识点“ ${name}”？`, {
          title: "删除确认",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          console.log(value);
          this.$http.delete(`/api/courses/${data.item.id}`).then((res) => {
            this.$bvToast.toast(`${name} 删除成功`, {
              title: `操作成功`,
              variant: "success",
              solid: true,
            });
            this.doSearch();
          });
        });
    },

    onEditHidden() {
      this.showEdit = false;
    },
  },
};
</script>