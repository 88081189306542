<template>
  <b-modal
    v-if="editItem != null"
    centered
    scrollable
    size="lg"
    title="知识点编辑"
    ok-title="保存"
    cancel-title="取消"
    :visible="visible"
    @hidden="onHidden"
    @ok="saveChanges"
  >
    <validation-observer ref="formRules">
      <!-- 基本信息 -->
      <app-collapse>
        <app-collapse-item title="基本信息" ref="baseInfo" :isVisible="true">
          <validation-provider
            #default="{ errors }"
            name="*标题*"
            rules="required"
          >
            <b-form-group
              label="标题"
              label-for="input-course-name"
              label-cols-md="3"
            >
              <b-form-input
                id="input-course-name"
                placeholder="标题"
                v-model="editItem.title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="*适用法律*"
            rules="required"
          >
            <b-form-group
              label="适用法律"
              label-for="input-course-apply"
              label-cols-md="3"
            >
              <b-form-select
                id="input-course-apply"
                placeholder="适用法律"
                v-model="editItem.apply"
                :options="complianceOptionsClean"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="*文档类型*"
            rules="required"
          >
            <b-form-group
              label="文档类型"
              label-for="input-course-type"
              label-cols-md="3"
            >
              <b-form-select
                id="input-course-type"
                placeholder="文档类型"
                v-model="editItem.type"
                :options="typeOptions"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </app-collapse-item>

        <app-collapse-item title="内容" ref="baseInfo">
          <validation-provider
            #default="{ errors }"
            name="*内容*"
            rules="required"
          >
            <!-- Field: Message - Quill Editor -->
            <div class="message-editor">
              <quill-editor
                id="quil-content"
                v-model="editItem.content"
                :options="editorOption"
              />
              <div id="quill-toolbar" class="d-flex border-0">
                <!-- Add a bold button -->
                <button class="ql-bold" />
                <button class="ql-italic" />
                <button class="ql-underline" />
                <button class="ql-align" />
                <button class="ql-link" />
              </div>
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </app-collapse-item>

        <app-collapse-item title="附件资料">
          <b-form-group
            label="附件资料"
            label-for="input-course-attachment"
            label-cols-md="3"
          >
            <b-form-input id="input-course-attachment" placeholder="附件资料" />
          </b-form-group>
        </app-collapse-item>
      </app-collapse>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormSelect,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  BBadge,
  BButton,
  BPagination,
  BFormGroup,
  BFormInvalidFeedback,
  BAvatar,
  BFormCheckbox,
} from "bootstrap-vue";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { quillEditor } from "vue-quill-editor";
import "@core/scss/vue/libs/quill.scss";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BAvatar,
    BFormCheckbox,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },

  props: ["item", "visible"],

  emits: ["changed"],

  data() {
    return {
      //validation
      required,
      confirmed,
      password,
      email,
      min,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
      alphaDash,

      //
      editItem: null,
      adminUser: null,

      complianceOptionsClean: [
        { value: null, text: "请选择应用法律", disabled: true },
        "GDPR",
        "CCPA",
        "PIPL",
      ],
      typeOptions: [
        { value: null, text: "请选择知识类型", disabled: true },
        { value: "law", text: "法律法规" },
        { value: "case", text: "经典案例" },
        { value: "explain", text: "条款解读" },
      ],

      editorOption: {
        modules: {
          toolbar: "#quill-toolbar",
        },
        placeholder: "知识点内容",
      },
    };
  },

  watch: {
    item(newVal, oldVal) {
      this.editItem = JSON.parse(JSON.stringify(this.item));
      this.loadAdminUser(this.item.adminUser);
    },
  },

  methods: {
    // load admin user info
    loadAdminUser(id) {
      if (!id) return;
      this.$http
        .get(`/api/enterprise/user/${id}`)
        .then((res) => {
          this.adminUser = res.data;
        })
        .catch((error) => {
          console.warn("Not good man :(");
        });
    },

    saveChanges() {
      this.$refs.formRules.validate().then((success) => {
        // debugger
        if (success || true) {
          this.$http
            .patch("/api/enterprise/" + this.editItem.id, {
              enterprise: this.editItem,
            })
            .then((res) => {
              //this.doSearch();
              this.$emit("changed");
            });
        }
      });
    },

    onHidden() {
      this.$emit("hidden");
    },
  },
};
</script>

<style scoped>
.inline-inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.inline-inputs > * {
  margin: 1rem 1.25rem;
}
</style>
<style>
.ql-container {
  border-bottom: 0 !important;
  min-height: 350px !important;
}
</style>